.ui.secondary.pointing.menu .active.item {
    color: @green !important;
    border-color: @green !important;
}

.ui.secondary.pointing.menu .item {
    color: @lightGrey !important;
}

.ui.secondary.pointing.menu {
    border-color: @lightGrey !important;
}

.add-new {
    background-color: #002c46 !important;
    cursor: pointer;
    color: #00dcb1 !important;
}
