/*******************************
         Theme Overrides
*******************************/

.ui.negative.message {
  background-color: @background;
}

.ui.positive.message {
  background-color: @background;
}

.ui.positive.message .response-msg-header {
  color: @green;
}

.ui.message.flex-center {
  justify-content: center;
  flex-direction: column;  
}
