@nav-width: 250px;
@sidebar-link-padding: 2px;
@secondary-nav-height: 55px;
@secondary-nav-active-height: @secondary-nav-height + 75px;

#vertical-nav {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: @lightGrey;
  width: @nav-width;
  min-width: @nav-width;
  transition: @layout-transition-duration cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 4;
  align-self: flex-start;
  // background-image: url('../../../assets/icons/ErisX_Icons_Navy3.svg');
  background-size: 400px 400px;
  background-position: 30px 100%;
  background-repeat: no-repeat;
  // In the unlikely case of a large amount of accounts, scroll!
  overflow-y: auto;
  overflow-x: hidden;

  .icon-ticker-container {
    flex-direction: row;
    display: flex;

    * {
      display: inline;
    }
  }

  .nav-header {
    padding-left: 8px;
    font-size: 30px;
    font-weight: 100;
  }

  .flex {
    * {
      margin: 0;
    }
  }

  .icon-md {
    margin: 0 5px;
  }

  .icon.icon-md {
    margin: 0 9px !important;
  }

  .vert-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .sidebar-link {
    font-size: 1.1em;
    justify-self: stretch;
    display: block;
    transition-property: background-color, color, font-weight;
    transition-duration: 0.4s;
    color: @lightGrey;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 22px;
    box-shadow: none;
    h3 {
      color: @color-primary-0;
      transition: all @layout-transition-duration ease;
      vertical-align: 0.5rem;
    }
  }

  .sidebar-link .title {
    transition: color 0.4s ease;
  }

  // .sidebar-link:hover,
  // .submenu {
  //   box-shadow: none;
  //   color: @green;
  // }

  .sidebar-link.flex {
    justify-content: space-between;
  }

  .sidebar-link.focus {
    background-color: #00304d;
    color: @green;
  }

  .sidebar-link.active .title {
    color: @green;
    font-weight: bolder;
    border-bottom: 0.5px solid @color-primary-3;
  }

  .sidebar-link .title:hover {
    color: @green;
    font-weight: bolder;
  }

  .sidebar-link .submenu {
    padding-left: 8px;
    padding-bottom: 2px;
    margin: 0.1rem 0;
    width: 100%;
    color: @lightGrey;
  }

  .sidebar-link.active .submenu {
    color: @lightGrey;
  }

  .sidebar-link.active .submenu.active,
  .sidebar-link.active .submenu:hover {
    color: @color-primary-0;
    border-left: 3px solid @green;
    padding-left: 12px;
    box-shadow: none;
  }

  // .sidebar-link .title {
  //   color: @lightGrey;
  //   padding-top: 5px;
  // }

  .content.active {
    animation: none;
    background-color: #00304d;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.primary-nav {
  max-height: calc(100% - @secondary-nav-height);
}

.secondary-nav {
  width: @nav-width;
  position: fixed;
  bottom: 0px;
  padding-bottom: 7px;
  padding-top: 7px;
  background-color: @color-primary-cboe-clear;
  box-shadow: 0 0 10px 5px @color-primary-cboe-clear;
  border-top: 1px solid @color-primary-cboe-clear;
  height: @secondary-nav-height;
}

.secondary-nav.active {
  height: @secondary-nav-active-height;
}

#vertical-nav.closed {
  transform: translateX(-@nav-width);
  flex: 0;
  position: absolute;
}

/* Semantic UI Sidebar-specific styling */
.sidebar {
  width: 100% !important;
  padding: 0 !important;

  #vertical-nav {
    transform: none;
    width: 100% !important;

    .secondary-nav {
      width: 100%;
    }
  }
}

#vertical-nav.secondary-active {
  max-height: calc(100% - @secondary-nav-active-height);
}

// class for when navbar overflows past secondary nav and needs to scroll
#vertical-nav.overflowed::after {
  content: '';
  display: block;
  background-color: magenta;
  width: 50px;
  height: 50px;
}

#vertical-nav .sidebar-widget {
  background-color: @color-primary-cboe-clear;
}

.widget-label {
  font-size: 13px !important;
  padding: 2px !important;
}

// .sidebar-link:hover,
// .submenu-fcm {
//   box-shadow: none;
//   color: @lightGrey;
// }
