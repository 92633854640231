.status.posted, .status.approved {
    color: #21BA45;
}

.status.rejected {
    color: red;
}

.tx-details-table {
    margin-bottom: 0px !important;
}

.bank-details-table {
    margin-top: 0px !important;
}

