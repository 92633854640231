.ReactTable.small {
  * {
    color: @lightGrey !important;
  }
  .rt-thead {
    border:none!important;
  }
  .rt-tr-group {
    border-top: 1px solid @color-primary-2 !important;
  }
}

#member-select-card {
  width: @nav-width;
  bottom: 0px;
  padding-top: 7px;
  background-color: @color-primary-3;
  box-shadow: 0 0 10px 5px @navy;
}
