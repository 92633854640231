
#advanced-view {
  color: @lightGrey !important
}

.balances-cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.balances-card {
  @media(min-width: @largestMobileScreen) {
    margin: 1rem 1rem  1rem 0rem !important;
  }
  background-color: @color-primary-3 !important;
  @media(max-width: @largestMobileScreen) {
    width: 100% !important;
  }
  .title {
    align-items: center !important;
    font-size: 18px;
  }
  .header {
    color: #DCDCE1 !important;
  }
  .meta {
    color: #9696a0 !important;
  }
  .balance {
    border-left: 3px solid @color-primary-0;
    transition: .5s ease;
    padding: .5rem
  }
  .action-buttons {
    transition: .5s ease;
    opacity: 0;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap;
    .button {
      border: 2px solid #FFFFFF;
      background-color: #0B375B;
      width: 150px;
    }
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  
  .middle:hover .action-buttons {
    opacity: 1;
  }

  .middle:hover .balance {
    opacity: 0;
  }

  .column {
    .label {
      color: #DCDCE1;
      font-size: 15px;
      opacity: 0.8;
      margin-bottom: 5px;
    }    
    .amount {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .decreasing {
    color: #FB8383;
    font-size: 18px;
  }
  .increasing {
    color: #21BA45;
    font-size: 18px;
  }
}

@keyframes flash-red {
  0% {
    background-color: #FB8383;
    opacity: 1;
  }
  100% {
    background-color: inherit;
  }
}

@keyframes flash-green {
  0% {
    background-color: #21BA45;
    opacity: 1;
  }
  100% {
    background-color: inherit;
  }
}

.flash-price-up {
  animation: flash-green;
  animation-duration: 1500ms;
}

.flash-price-down {
  animation: flash-red;
  animation-duration: 1500ms;
}
