
.transfer-funds-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: height @layout-transition-duration ease-out;

  .ui.segment {
    width: 100%;
  }
  @media(max-width: 768px) {
    .ui.segment {
      padding: 0 0 10px 0;
    }
  }
  .deposit-withdrawal-footer {
    text-align: center;
  }
}

.transfer-funds-left {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  * {
    border: none !important;
  }
  :not(:last-of-type) {
    border-bottom: solid 1px @color-primary-1  !important;
  }
}

.transfer-funds-right {
  flex: 2;
  margin-top: 3px;
  height: 100%;
}

.transfer-funds-left-item {
  flex: 1;
  margin: 3px;
  width: 200px;
}

.funds-transfer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.funds-transfer-amount {
  display: flex;
  flex-direction: row;
}

.transaction-confirm {
  width: 80%;
}

.information-grid {
  .ui.grid {
    background-color: #15446E;
    color: #7EC2FF !important;
  }
  .column {
    .label {
      font-size: 14px;
      margin-bottom: 5px;
    }    
    .amount {
      font-size: 24px;
      line-height: normal;
    }
    @media(max-width: 768px) {
      .label {
        font-size: 10px;
        margin-bottom: 2px;
        line-height: 1em;
      }    
      .amount {
        font-size: 16px;
        line-height: normal;
      }
    }

  }
  .point {
    font-size: 14px;
    padding-top: 5px;
  }
  @media(max-width: 768px) {
    .point {
      font-size: 10px;
      padding-top: 5px;
      line-height: 1em;
    }
  }
}
