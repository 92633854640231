/*******************************
             Label
*******************************/

.ui.basic.label, input:focus + .ui.label {
  background: transparent;
  border: none;
  border-bottom: 1px solid @darkGrey;
  color: @mutedTextColor;
  transition: border-color @defaultDuration @defaultEasing;
}

.ui.input > input:focus + .ui.label {
  border-color: @primaryColor;
}