/*******************************
        Global Overrides
*******************************/

a {
  transition: all @defaultDuration @defaultEasing;
  text-decoration: none;
}

.italic {
  font-style: italic;
}

.env-banner {
  grid-area: env-banner;
  width: 100%;
  display: block;
  background-color: @orange;
  color: @white;
  text-align: center;
  font-size: 1.5em;
  height: @bannerHeight;
  line-height: 2.2rem;
}

.notif-banner {
  grid-area: notif-banner;
  width: 100%;
  display: block;
  background-color: @orange;
  color: @white;
  text-align: center;
  font-size: 1.2em;
  line-height: 2.2rem;
}

textarea {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid @inputBorderColor;
  color: @textColor;
  transition: all @defaultDuration @defaultEasing;
  font: @fontSize @fontName;
}

textarea:focus {
  border-color: @primaryColor;
}

.all-caps {
  text-transform: uppercase;
}
