small.helper.error {
    color: #ff6e6e !important;
}

small.helper.info {
    color: @darkGrey !important;
}

.error.field input {
    border-color: #ff6e6e !important;
}
