.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: 800;
}

.inline-block {
  display: inline-block;
  margin: 0 1em;

  &:first-child {
    margin: 0 1em 0 0;
  }
  &:last-child {
    margin: 0;
  }
}
