.profile-segment {
  display: flex;
  padding: 10px !important;
  margin-right: 2px;
  justify-content: space-between;
  background-color: transparent !important;
}

.pii-item.header {
  font-size: 29px;
}
