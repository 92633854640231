@import (less) '../../node_modules/normalize.css/normalize.css';
@import (less) '../../node_modules/react-table/react-table.css';
@import (less) '../../node_modules/flatpickr/dist/themes/airbnb.css';
@import (less) '../../node_modules/erisxkit/common/less/loader.less';
@import (less) './_variables';

@import './semantic-ui-less/semantic';
@import './_classes';
@import './components/_vertical-nav';
@import './components/_top-nav';
@import './components/_transfer-funds';
@import './components/_balances-card';
@import './components/_holdings';
@import './components/_contentSidebar';
@import './components/_list';
@import './overrides/_defaults';
@import './components/_onBoarding';
@import './components/_fileUpload';
@import './_variables';
@import './components/_stepNav';
@import './components/_linkedAccounts';
@import './components/_toast';
@import './components/_react-datetime.less';
@import './components/_input';
@import './components/_depositWithdrawalContainer';
@import './components/_history';
@import './components/_apiKeys';
@import './overrides/_withFilters';
@import './components/_flatpickr';
@import './components/_.orderEntry';
@import './components/_profile';

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -999;
  }
}

@keyframes appear {
  from {
    transform: translateY(-5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

html {
  height: 100%;
}

// body {
//   height: 100%;
//   margin: 0px;
//   /*removes default style*/
//   box-sizing: border-box;
//   background-color: @color-primary-4 !important;
// }

#root {
  height: 100%;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'env-banner env-banner'
    'notif-banner notif-banner'
    'sidebar content';
  grid-template-columns: @nav-width auto;
  /* template rows -must- use 1fr instead of 100% to take topnav / notif. banners into account */
  grid-template-rows: auto auto 1fr;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
}

.grid-container.nav-closed {
  grid-template-columns: 0 100%;
}

.content-container {
  grid-area: content;
  height: 100%;
  overflow: hidden;
}

.loading-screen {
  background-color: @navy;
}
// .pusher {
//   overflow: hidden !important;
//   min-height: calc(100%-60px);
//   margin-top: 1em;
//   @media (max-width: @largestMobileScreen) {
//     margin-top: 0.3em !important;
//   }
// }

#profile {
  @media (min-width: @largestMobileScreen) {
    max-width: ~'min(80vw, 150ch)';
  }
}

.pushable.withBanner {
  height: calc(100% - @top-nav-height - @bannerHeight);
}

.pushable.withSidebar {
  overflow: unset !important;
}

.hide {
  animation: hide @layout-transition-duration ease-out 0s 1 normal forwards;
}

.content {
  animation: appear @layout-transition-duration ease;
  height: calc(100% - @top-nav-height);
  @media (max-width: @largestMobileScreen) {
    height: calc(100% - 230px);
  }
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.content.onBoarding {
  @media (max-width: @largestMobileScreen) {
    height: calc(100% - 110px) !important;
  }
}

.content.withNotifBanner {
  @media (max-width: @largestMobileScreen) {
    height: calc(100% - 290px) !important;
  }
}

.main-holdings {
  margin: 0 0 10px 0;
  padding: 10px;
  @media (max-width: @largestMobileScreen) {
    padding: 0 !important;
  }
  .holdings-header {
    justify-content: flex-end;
  }
}

.deposit-withdraw-header {
  display: flex;
  justify-content: space-between;
}

.balances-header {
  display: flex;
  justify-content: space-between;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1em;
  background-color: @color-primary-3;
  z-index: 999;
}

.reports.flex {
  align-content: space-between;
  align-items: stretch;
  .segment {
    flex-grow: 1;
    margin: 1.2em 0.6em;
  }
}

a {
  outline: none;
  cursor: pointer;
}

.input.meta {
  float: right;
  font-size: x-small;
  color: grey;
}

.crypto-icon-USD::after,
.crypto-icon-usd::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/usd.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-BTC::after,
.crypto-icon-btc::after,
.crypto-icon-TBTC::after,
.crypto-icon-tbtc::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/btc.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-LTC::after,
.crypto-icon-ltc::after,
.crypto-icon-TLTC::after,
.crypto-icon-tltc::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/ltc.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-BCH::after,
.crypto-icon-bch::after,
.crypto-icon-TBCH::after,
.crypto-icon-tbch::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/bch.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-ETH::after,
.crypto-icon-eth::after,
.crypto-icon-TETH::after,
.crypto-icon-teth::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/eth.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-xrp::after,
.crypto-icon-XRP::after,
.crypto-icon-TXRP::after,
.crypto-icon-txrp::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/xrp.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-usdc::after,
.crypto-icon-USDC::after,
.crypto-icon-TEST::after,
.crypto-icon-test::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/usdc.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-aave::after,
.crypto-icon-AAVE::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/aave.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.crypto-icon-link::after,
.crypto-icon-LINK::after {
  width: @icon-md;
  height: @icon-md;
  display: inline-block;
  content: '';
  background: url('../../node_modules/cryptocurrency-icons/svg/white/link.svg')
    no-repeat;
  background-size: cover;
  margin: 0 5px;
}

.bank-icon {
  background-color: #fff;
  color: @color-primary-4;
  border: none !important;
}

.icon-sm::after {
  width: @icon-sm;
  height: @icon-sm;
}

.icon-md::after {
  width: @icon-md;
  height: @icon-md;
}

.icon-lg::after {
  width: @icon-lg;
  height: @icon-lg;
}

.icon-hg::after {
  width: @icon-hg;
  height: @icon-hg;
}

.terms-container {
  max-height: 50vh;
}

.mobile-error {
  max-width: 35em;
  margin: 5em 1em;
}

.overlay-form {
  position: relative;

  .success-msg-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .success-msg {
      align-self: center;
    }
  }
}

.referralCodeIcons {
  color: white;
  cursor: pointer;
  && {
    margin-right: 15px;
  }
}

.noMarginRight {
  button {
    margin-right: 0px !important;
  }
}
