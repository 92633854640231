::selection,
::-moz-selection {
  background-color: @green !important;
  color: @navy !important;
}

@font-face {
  font-family: 'Apercu';
  src: url('../../../assets/fonts/apercu-regular.woff') format('woff'),
    url('../../../assets/fonts/apercu-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('../../../assets/fonts/apercu-bold.woff') format('woff'),
    url('../../../assets/fonts/apercu-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('../../../assets/fonts/apercu-italic.woff') format('woff'),
    url('../../../assets/fonts/apercu-italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Apercu Mono';
  src: url('../../../assets/fonts/apercu-mono-regular.woff') format('woff'),
    url('../../../assets/fonts/apercu-mono-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

// Remove ugly dotted line from selected DOM nodes
* {
  outline: none;
}

.mono {
  font-family: 'Apercu Mono' !important;
  font-weight: 400 !important;
  *:not(.icon) {
    font-family: 'Apercu Mono' !important;
  }
}

.huge.mono {
  font-size: 2.5rem !important;
}

.input.mono {
  * {
    font-family: 'Apercu Mono' !important;
  }
}

// Style everything that isn't an icon with our official font
// This might change later. It's very much a heavy-handed solution.
body *:not(.icon):not(.mono):not(h3):not(.mono input) {
  font-family: 'Apercu' !important;
}

#root .ui {
  box-shadow: none;
}

// body,
// .ui {
//   color: @lightGrey !important;
// }

.page-header,
.page-header .sub.header {
  color: @green !important;
}

h3 {
  font-family: 'Apercu Mono' !important;
  text-transform: uppercase;
}

h4 {
  color: @darkGrey !important;
}

.flex {
  display: flex !important;
  align-content: center;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 10px 0px;
  align-items: center;
}

.ui.checkbox label::after {
  background-color: @green !important;
}

.ui.checkbox label::before {
  background-color: @color-primary-1 !important;
}

.ui.checkbox.checked label::before {
  background-color: @color-primary-1 !important;
}

.pending.icon {
  color: @withdraw-hover !important;
  background-color: transparent !important;
}

.pull-right {
  text-align: right;
}

.pull-left {
  text-align: left;
}

.ReactTable {
  .tr-th {
    border: 1px 0px solid transparent;
  }

  .rt-noData {
    color: @lightGrey;
    background-color: @navy;
  }

  .dark {
    border: none;
    padding: 0px;
  }

  .rt-thead {
    .rt-td {
      line-height: 1.5;
    }

    // style the first header div
    .rt-th div:first-of-type {
      padding: 0 0.5rem;
      font-size: 11px;
    }

    // style the resizable handle
    .rt-th .rt-resizable-header-content {
      border-right: 1px solid @navy;
    }

    .rt-th.-sort-asc {
      box-shadow: inset 0 1px 0 0px @green;
      transition: none;
    }

    .rt-th.-sort-desc {
      box-shadow: inset 0 -1px 0 0px @green;
      transition: none;
    }
  }

  .rt-th.rt-resizable-header {
    border: none;
  }

  .rt-thead.-header {
    background-color: @color-primary-1;
    height: 1.75rem;
    font-weight: bold;
  }

  .rt-tbody {
    background-color: @color-primary-3;
    overflow: hidden;

    .rt-tr-group {
      border-color: @color-primary-2;
    }

    .rt-td {
      border: none;
      padding: 0.5rem 0.75rem;
    }
  }

  .rt-thead.-headerGroups {
    display: none;
  }

  .-loading > div {
    position: unset;
  }

  .-loading,
  .rt-noData {
    background: none;
  }

  .rt-th {
    overflow: visible !important;
  }

  .pagination-bottom {
    background-color: transparent;

    button {
      background-color: @green!important;
      color: @navy!important;
      border-radius: 0 !important;
    }

    button:not(:disabled):hover {
      background-color: #00d2a5 !important;
    }

    button:not(:disabled):active {
      background-color: #00b891 !important;
    }
  }

  .rt-tr.disabled {
    opacity: 0.45;
    font-style: italic;
  }
}

.ReactTable.with-subtable
  > .rt-table
  > .rt-tbody
  > .rt-tr-group:nth-child(odd) {
  background-color: #f1fafd;
}

.rt-tr-group .ReactTable.subtable {
  box-shadow: 0 0 0 1px @navy;
  margin: 2em;
}

.nowrap {
  white-space: nowrap;
}

#widget-dropdown.ui.dropdown {
  .default.text {
    opacity: 50%;
    padding-top: 6px;
    padding-left: 22px;
  }

  .text {
    padding-top: 6px;
    padding-left: 22px;
  }
}

/* do not show arrows for number input in quick trade widget */
/* source: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
/* Chrome, Safari, Edge, Opera */
input.order-entry-widget-input::-webkit-outer-spin-button,
input.order-entry-widget-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number].order-entry-widget-input {
  -moz-appearance: textfield;
}

.ui.dropdown{
  padding-left: 0px !important;
  div.default.text {
    opacity: 50%;
  }
}

#root ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 50%; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 50%; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 50%; /* Firefox */
}

.pulse-button {
  cursor: pointer;
  border-color: red;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  -webkit-animation: ripple 3s infinite;
}
.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 @green, 0 0 0 0 @green;
  }
  40% {
    box-shadow: 0 0 0 15px rgba(255, 0, 64, 0), 0 0 0 0 @green;
  }
  80% {
    box-shadow: 0 0 0 15px rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
  }
}

.info-bottom{
  margin-bottom: 1em;
  div.field {
    margin-bottom: 0px!important;
  }
}

.info-bottom-label{
  font-family: Roboto!important; 
  font-style: italic!important; 
  font-weight: normal!important;
  font-size: 11px!important;
  align-items: center!important;
  color: #DADADA!important;
  margin-top:1px;
}

.max-width-flex{
  display: flex; 
  width: 100%;
  div.firstDiv{
    width: 49%; 
  }

  div.lastDiv {
    width: 49%; 
    margin-left: 2%!important;
  }
}