.dropzone {
  cursor: pointer;
}
.dropzone:hover {
  background-color: #00304d !important;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.upload-area {
  background-color: #00ebb9;
  margin-bottom: 0.8em;
  color: #002c46;
  .buttonBgText {
    color: #002c46;
  }
  .vert-flex.drop-content {
    height: 100%;
    justify-content: center;
    color: @lightGrey;
    cursor: pointer;

    .title {
      color: #002c46 !important;
      font-weight: bold;
    }

    .desc {
      color: #002c46 !important;
    }
  }
}

.uploaded-area {
  border: 1px solid #00ebb9;
  margin-bottom: 0.8em;
  .title {
    color: white !important;
    font-weight: bold;
  }

  .desc {
    color: white !important;
  }
}

.min-reqs {
  font-size: 12px !important;
}

@media (min-width: 500px) {
  .centered-text {
    margin-left: 80px;
  }
}
