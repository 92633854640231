.ui.icon.input {
  background: transparent;
}

.ui.labeled.input:not(.corner) .label {
  line-height: @lineHeight;
}

.ui.input input,
.ui.textarea textarea,
.field textarea {
  transition: all @defaultDuration @defaultEasing!important;
  border-bottom: 1px solid @borderColor;
  border-width: 0 0 1px 0 !important;
}

.ui.input input:focus,
.ui.textarea textarea:focus,
.field textarea:focus {
  border-width: 0 0 1px 0 !important;
}

.field.outline .ui.input > input {
  padding-left: 1rem !important;
  border: 1px solid @tertiaryColor !important;
}

.field.outline .ui.input > .icon {
  opacity: 1;
  color: @tertiaryColor;
  border-left: 1px solid @tertiaryColor !important;
}

.field.mono > label {
  font-family: @pageFont !important;
}
