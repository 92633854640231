.ui.steps .step:after {
  position: absolute;
  z-index: 2;
  content: '';
  top: 40%;
  right: 0;
  left: 72%;
  border: medium none;
  background-color: @arrowBackgroundColor;
  width: 5.714em; 
  height: 0;
  border-style: solid;
  border-color: @lightGrey;
  border-width: 1px 0 0px 0;
  transition: @transition;
  transform: none;
}

.ui.steps .step.disabled:after {
  border-color: @darkGrey;
}

.ui.steps .step > .icon {
  margin: 0 0 5px 0 !important;
}
