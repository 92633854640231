.ui.selection.dropdown,
.ui.selection.dropdown:hover,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active {
  box-shadow: @menuBoxShadow !important;
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  border-color: @primaryColor;
  background: transparent !important;
}

.ui.selection.dropdown {
  background-color: @background;
  border-color: @darkGrey !important;
  border-width: 0 0 1px 0 !important;
}

.fields .ui.selection.dropdown {
  line-height: @lineHeight;
}

.ui.selection.dropdown .menu > .item  {
  border: none!important;
}

.ui.selection.dropdown .menu > .item:hover  {
  background-color: @primaryColor!important;
}

.ui.selection.dropdown .menu > .item .text {
  background-color: transparent !important;
}

.ui.dropdown .menu > .disabled.item {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  .text {
    opacity: .45;
  }
}
