// Flatpickr ErisX theme
// Requires base AirBnB theme

.flatpickr-calendar {
  border-radius: 0;
  width: 250px;
  height: 250px;

  * {
    border: none!important;
    font-size: 14px;
  }
}

.flatpickr-current-month {
  line-height: 1.75;
}

.flatpickr-innerContainer {
  height: 238px;
}

.dayContainer {
  height: 185px;
}

.flatpickr-days, .dayContainer {
  max-width: 250px;
  min-width: 250px;
}

.flatpickr-day {
  height: 28px;
  line-height: 2;
}

.flatpickr-day.prevMonthDay {
  color: @color-primary-1;
}

.flatpickr-weekday,
.flatpickr-day.flatpickr-disabled {
  color: @lightGrey;
}

.flatpickr-weekday {
  font-weight: 600;
}

.flatpickr-monthDropdown-months {
  color: @color-primary-1;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-size: 14px;
  font-weight: 600;
  color: @color-primary-4;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  transition: fill @layout-transition-duration ease;
}


.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: @color-primary-1;
}

.flatpickr-day {
  transition: all @layout-transition-duration ease;
  color: @color-primary-4;
}

.flatpickr-day:not(.selected):not(.flatpickr-disabled):hover {
  background: @darkGrey;
  color: white;
}

.flatpickr-day.selected:not(.flatpickr-disabled):hover {
  background: @color-primary-1;
}

.flatpickr-day, .span.flatpickr-day.nextMonthDay {
  width: 1em;
}

.flatpickr-day.selected.startRange:before {
  content: '';
  display: inline-block;
  border-left: 25px solid transparent;
  border-bottom: 25px solid @color-primary-4;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
}

// override extremely specific cases for blue backgrounds
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: @color-primary-4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.nextMonthDay.selected {
  border: solid 2px @color-primary-4;
  color: white;
  text-shadow: 0px 1px 1px @color-primary-1, 0 1px 2px black;
  font-weight: 600;
  background-color: @color-primary-4;
}

.flatpickr-day.today:before,
.flatpickr-day.selected.today:before {
  content: '';
  display: inline-block;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #00ebb9;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.flatpickr-day.selected.startRange:not(.endRange):after {
  content: '';
  z-index: -1;
  display: inline-block;
  border-left: 2.5em solid transparent;
  border-bottom: 2.0em solid #e9e9e9;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.flatpickr-day.selected.endRange:not(.startRange):after {
  content: '';
  z-index: -1;
  display: inline-block;
  border-right: 2.5em solid transparent;
  border-top: 2.0em solid #e9e9e9;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  left: 0px;
}
