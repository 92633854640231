/*******************************
         Tab Overrides
*******************************/

.ui.menu {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
}

.ui.secondary.menu::after {
  display:none;
}

.ui.secondary.menu .item {
  transition: all @defaultDuration @defaultEasing;
  text-transform: uppercase;
  color: @textColor;
  background-color: transparent;
}

.ui.menu a.item:hover,
.ui.secondary.menu a.item:hover {
  color: @white;
  background: transparent;
}

.ui.menu .active.item,
.ui.secondary.menu .active.item {
  color: @primaryColor;
  background-color: transparent!important;
}

.ui.menu .active.item:hover,
.ui.secondary.menu .active.item:hover {
  color: @primaryColor;
}

.ui.menu .item::after {
	content: '';
  transition: inherit;
	width: 0%;
	height: 2px;
	background-color: #00ebb9;
	bottom: 0;
  position: absolute;
}

.ui.menu .active.item::after {
	width: 100%;
  left: 0;
}
