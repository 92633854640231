.back-link,
.back-link * {
  cursor: pointer;
  transition: all 0.25s ease;
}

.back-link:hover {
  color: @color-primary-0 !important;
  * {
    color: @color-primary-0;
  }
}

.back-link:active {
  color: #00b891 !important;
  * {
    color: #00b891;
  }
}

.transfer-funds-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; // temporarily align to column so container doesn't stretch ugily (sp?)
  .transfer-funds-container {
    margin: 15px;
    flex-grow: 3;
    min-width: 66.6%;
  }
  @media(max-width: 768px) {
    .transfer-funds-container {
      overflow: visible;
      padding-bottom: 50px;
    }
  }
  .transfer-funds-sidebar {
    flex-grow: 1;
    margin: 15px;
  }
}

.transfer-funds-container {
  .crypto-address input {
    text-overflow: ellipsis;  
  }
  .deposit-withdraw-container {
    padding: 0 20% 5% 20%;
  }
  .deposit-withdraw-container.mobile {
    padding: 0 2% 5% 2%;
  }
}

.crypto-deposit {
  display: flex;
  .ui.segment {
    flex: 1;
  }
  .ui.form {
    flex: 1;
  }
}

.deposit-notification {
  color: #FFFFFF !important;
  font-size: 18px !important;
}

.ach-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ach-confirm-wrapper {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ach-confirm-label {
  padding-bottom: 15px;
  font-size: 14px !important;
}

.ach-actions {
  display: flex;
  justify-content: space-between;
}

.ach-confirm-note {
  color: #7EC2FF;
  text-align: center;
  padding-top: 20px;
  margin: 0 15% 0 15%;
}

.ach-confirm-modal {
  padding: 20px;
}
