.vert-flex {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.header.review-pii {
  text-align: center;
}

.pii-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 25px;
}

.pii-segment {
  display: flex;
  width: 50%;
  padding: 28px !important;
  margin-right: 2px !important;
  align-items: flex-start;
  min-width: 360px !important;
}

.pii-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  .pii-title {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 2px;
    color: @darkGrey;

    &.profile {
      color: @green !important;
    }
  }
}

.pii-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 25px;

  :last-child {
    padding-bottom: 0px;
    min-height: 35px;
  }
}

.pii-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.terms-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  :first-child {
    flex: 1;
    box-shadow: none;
  }

  :last-child {
    flex: 6;
  }
}

.vert-flex .ui.segment.review-items {
  text-align: center;
  width: 85% !important;
  background-color: @navy !important;

  *:not(button, button > *, a) {
    background-color: @navy !important;
    color: @lightGrey;
  }
}

.top-button {
  margin-bottom: 10px !important;
}

.onboarding-container {
  justify-content: center;
  display: flex;

  .medium-width {
    width: 342px;
  }
}

.security-question {
  padding-bottom: 20px;
}

.onboarding-header {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15px;
  font-size: 18px;
}

.onboarding-desc {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15px;
  font-size: 12px;
  color: @darkGrey !important;
}

.onboarding-content {
  justify-content: space-between;
  align-self: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 90%;
  min-height: 35rem;
  background-color: @navy;

  @media (max-width: @largestMobileScreen) {
    padding: 5px !important;
  }
}

.onboarding-content.auth-messages {
  justify-content: flex-start;
}

.onboarding-content.scrollable {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-height: @largestMobileScreen) {
    max-height: 40rem;
  }

  @media (max-height: @tiny) {
    max-height: 35rem;
  }
}

.onboarding-content.terms {
  min-height: 25rem;
  justify-content: space-around;
}

.onboarding-information {
  max-width: 500px;
  min-height: 35rem;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.onboarding-content.borderless {
  border: none;
}

.onboarding-content.transparent {
  background-color: transparent;
}

.onboarding-content.center {
  justify-content: center;
}

.questions-container {
  padding: 10px;
}

.additional-fields {
  padding: 0em 1.5em;
}

.ob-form-footer {
  justify-content: right;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.field-with-error {
  min-height: 4.6rem;
}

#root .welcome-container {
  .segment {
    border: none !important;
  }

  .segment,
  .card {
    background-color: transparent;
  }

  input,
  select,
  .selection {
    background-color: @color-primary-1 !important;
  }

  .icon,
  .description,
  .title {
    color: @lightGrey;
  }

  .step,
  .step .content,
  .step::after {
    background-color: @color-primary-2 !important;
  }

  .active.step,
  .active.step .content,
  .active.step::after {
    background-color: @color-primary-1 !important;
  }

  .active.step {

    .icon,
    .description,
    .title {
      color: @green;
    }
  }

  .ui.form .field>label {
    color: @lightGrey;
  }

  .content {
    width: auto;
  }

  .onboarding-steps.done {
    border: 1px solid @lightGrey;
  }

  .onboarding-steps.disabled {
    border: 1px solid @darkGrey;

    * {
      color: @darkGrey !important;
    }

    cursor: initial;
    transform: none;
  }

  .onboarding-steps.active {
    border: 1px solid @lightGrey;

    * {
      color: @color-primary-4 !important;
    }

    background-color: @lightGrey;
  }

  .onboarding-steps {
    height: 18rem;
    width: 24rem;
    padding: 3px 6px;
    margin: 15px;
    border: 1px solid @darkGrey;
    text-align: center;
    background-color: transparent;

    .header {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      font-size: 1em;
      color: @darkGrey;

      .icon {
        margin-top: 0px;
      }
    }

    .huge.icons {
      width: 78px;
      align-self: center;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-top: 30%;
      color: @lightGrey;
    }

    .content {
      .header {
        justify-content: center;
        font-size: 2em;
        color: @darkGrey;
        text-align: center;
      }

      .description {
        color: @darkGrey;
        text-align: center;
      }

      height: min-content;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-top: none;
    }
  }
}

.password-str {
  font-weight: normal;
  font-style: italic;

  &.weak,
  &.very weak {
    color: #ff6e6e !important;
  }

  &.marginal {
    color: #ffbb33 !important;
  }

  &.good,
  &.strong,
  &.very strong {
    color: @green !important;
  }
}

.erisx-icons-bg {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.fcm-icon {
  opacity: 0.1;
}

.erisx-icon-bg {
  height: 100%;
  width: 100%;
}

.erisx-icon-bg.left {
  background: left -5rem top -5em e('/') 50rem 50rem no-repeat url('../../../assets/icons/ErisX_Boxes_Navy2.svg'),
    left -5rem top 40rem e('/') 50rem 50rem no-repeat url('../../../assets/icons/ErisX_Steps_Navy2.svg');
}

.erisx-icon-bg.right {
  background: left 7rem top 10rem e('/') 75rem 75rem no-repeat url('../../../assets/icons/ErisX_Circle_Navy2.svg');
}

.radio-addn-doc {
  padding: 5px;
}

.radio-addn-doc-border {
  border: solid 1px;
  width: 100%;
  border-radius: 15px;
  border-color: #002c46;
  background-color: #002c46;
}

.radio-addn-doc-border-active {
  border: solid 1px;
  width: 100%;
  border-radius: 15px;
  border-color: #00ebb9;
  background-color: #002c46;
}

.radio-addn-doc-border label {
  margin: 5px;
}

.radio-addn-doc-border-active label {
  margin: 5px;
}

@media (min-width: 450px) {
  .reqs-address {
    width: 400px;
  }
}

@media (man-width: 450px) {
  .reqs-address {
    width: 200px;
  }
}

#webcam-error {
  top: 130px;
  position: absolute;
}

#webcam-confirm {
  width: 90%;

  img {
    margin: auto;
  }
}

.fade-in&.i05 {
  width: 210px;
  margin-top: 10px;
}

.first-button-recomendation {
  width: 210px;
  margin-top: 10px;
}

.modal-recomendation {
  justify-content: center;
  display: flex;
  margin-bottom: 45px;
  margin-top: 20px;
}

.modal-recomendation-header {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 30px;
}

.selfie-recomendation a {
  color: #00ebb9;
  font-weight: 700;
  text-decoration: none !important;
}

.selfie-recomendation a:hover,
.selfie-recomendation a:visited,
.selfie-recomendation a:link,
.selfie-recomendation a:active {
  text-decoration: none !important;
}

.onboarding-content-message-footer a:hover,
.onboarding-content-message-footer a:visited,
.onboarding-content-message-footer a:link,
.onboarding-content-message-footer a:active {
  text-decoration: none !important;
}

@media (min-width: 325px) and (max-width: 680px) {
  .selfie-recomendation-img {
    background-image: url('../../../assets/icons/selfie_mockup.png');
    height: 245px;
    width: 305px;
    margin: auto;
    margin-top: 3%;
    background-size: 305px 245px;
    background-repeat: no-repeat;
  }

  .selfie-recomendation-container-img {
    margin: 0px auto 35px auto;
    border: solid 2px #0a3557;
    height: 270px;
  }
}

@media (max-width: 325px) {
  .selfie-recomendation-img {
    background-image: url('../../../assets/icons/selfie_mockup.png');
    height: 245px;
    width: 305px;
    margin-top: 3%;
    background-size: 305px 245px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 681px) {
  .selfie-recomendation-img {
    background-image: url('../../../assets/icons/selfie_mockup.png');
    height: 360px;
    width: 478px;
    margin: auto;
    margin-top: 3%;
  }

  .selfie-recomendation-container-img {
    margin: 0px auto 35px auto;
    border: solid 2px #0a3557;
    width: 650px;
    height: 400px;
    margin: auto;
  }
}

@media (min-width: 580px) {
  .circle-number {
    background-color: transparent;
    border: 2px solid #00ebb9;
    border-radius: 50%;
    width: 55px;
    min-width: 55px;
    height: 55px;
  }

  .circle-number p {
    margin: 2px auto;
    width: 18px;
    height: 60px;
    color: #00ebb9;
    font-size: 35px;
  }

  .fade-in>p {
    margin-top: 13px;
    margin-left: 10px;
    margin-bottom: 25px;
  }

  .selfie-recomendation {
    margin: auto;
    width: 75%;
    font-size: 18px;
  }

  .selfie-recomendation>p {
    text-align: center;
    width: 85%;
    margin: auto auto 28px auto;
  }

  .fade-in&.i01 .circle-number p {
    width: 13px !important;
  }

  .fade-in&.i04 .circle-number p {
    width: 21px !important;
  }
}

@media (max-width: 580px) {
  .circle-number {
    background-color: transparent;
    border: 2px solid #00ebb9;
    border-radius: 50%;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }

  .selfie-recomendation-container-img {
    margin: 0px auto 35px auto;
    border: solid 2px #0a3557;
    height: 270px;
  }

  .circle-number p {
    margin: auto;
    width: 10px;
    height: 00px;
    color: #00ebb9;
    font-size: 20px;
  }

  .fade-in>p {
    margin-top: 4px;
    margin-left: 10px;
    margin-bottom: 7px;
  }

  .selfie-recomendation {
    margin: auto;
    font-size: 16px;
  }

  .fade-in&.i02 {
    margin-top: 17px;
  }

  .fade-in&.i03 {
    margin-top: 17px;
  }

  .fade-in&.i04 {
    margin-top: 17px;
  }

  .fade-in.i01 .circle-number p {
    width: 7px;
  }

  .fade-in&.i04 .circle-number p {
    width: 11px !important;
  }
}

@media (min-width: 395px) and (max-width: 580px) {
  .fade-in&.i02 {
    margin-top: 17px;
  }
}

.fade-in {
  opacity: 0;
  display: flex;

  &.i01 {
    animation: fade-in-item 1s 0.5s ease-out forwards;
  }

  &.i02 {
    animation: fade-in-item 1s 1.5s ease-out forwards;
  }

  &.i03 {
    animation: fade-in-item 1s 2.5s ease-out forwards;
  }

  &.i04 {
    animation: fade-in-item 1s 3.5s ease-out forwards;
  }

  &.i05 {
    animation: fade-in-item 1s 4.5s ease-out forwards;
  }
}

@-webkit-keyframes fade-in-item {
  0% {
    display: block;
    opacity: 0;
  }

  30% {
    display: block;
    opacity: 0.5;
  }

  60% {
    display: block;
    opacity: 0.8;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.onboarding-content-message {
  width: 584px;
  height: 560px;
  margin: 5% auto;

  &>div {
    margin: 10px auto;
  }
}

@media (max-width: 500px) {
  .onboarding-content-message-main {
    width: 100% !important;
    margin: auto;
    text-align: center;
    font-size: 16px;
    height: 330px;

    &>div {
      width: 100%;
    }

    .rectangle {
      width: 83% !important;
      min-height: 46px;
      left: 808px;
      top: 379px;

      &>p {
        margin: 12px auto;
        font-size: 12px;
      }
    }

    .square {
      width: 16% !important;
      top: 379px;

      &>div {
        margin-top: 14px;
        font-weight: 700;
      }
    }
  }
}

.onboarding-content-message-main {
  width: 400px;
  margin: auto;
  text-align: center;
  font-size: 18px;
  height: 330px;

  &>div {
    margin-bottom: 25px;
    display: flex;
  }

  Button {
    font-size: 16px;
  }

  .square {
    width: 50px;
    top: 379px;
    display: flex;
    justify-content: center;
    align-items: center;

    //background: #F05A5C;
    &>div {
      //color: #CD0E05;
      margin-top: 5px;
      font-weight: 700;
    }
  }

  .s-error {
    background: #f05a5c;

    &>div {
      color: #cd0e05;
    }
  }

  .s-warning {
    background: #fea92a;

    &>div {
      color: #d2610f;
    }
  }

  .s-information {
    background: #5bc7ee;

    &>div {
      color: #12749a;
    }
  }

  .rectangle {
    width: 382px;
    min-height: 46px;
    left: 808px;
    top: 379px;
    padding: 3px;

    &>p {
      margin: 0px auto;
      font-size: 16px;
      min-height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .r-error {
    background: #f7f7f7;

    &>p {
      color: #cd0e05;
    }
  }

  .r-warning {
    background: #f7f7f7;

    &>p {
      color: #d2610f;
    }
  }

  .r-information {
    background: #f7f7f7;

    &>p {
      color: #12749a;
    }
  }

  .onboarding-content-message-button {
    margin-top: 10%;
    height: 62px;
    width: 260px;
    font-size: var(--lg-font-size);
  }

  .relative-position {
    position: relative;
  }

  .onboarding-content-message-footer {
    margin-top: 10%;

    &>span {
      color: #00ebb9;
    }
  }
}

.no-margin {
  margin: 0;
}

.onboarding-messages {
  justify-content: center;
  display: flex;
}

@media (min-height: 300px) and (max-height: 750px) {
  .onboarding-messages {
    height: 100% !important;
    margin-bottom: 100px !important;
  }
}