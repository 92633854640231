#root .ui.top.sidebar {
    height: 100% !important;
    width: 100% !important;
  }

#root .ui.bottom.sidebar {
  height: 455px !important;
  width: 100% !important;
  border-radius: 15px !important;
}

.content-sidebar {
  padding-top: 1em!important;
}

.content-sidebar, .content-sidebar * {

    .close.icon {
        float: right;
        font-size: 1.1em;
        z-index: 5;
        display: block;
        position: absolute;
        right: 5px;
        top: 10px;
      }
  }

.content-sidebar, .content-sidebar *:not(input):not(.text):not(span):not(.icon):not(.item):not(button) {
  input, select, .selection {
    background-color:@color-primary-4!important;
  }
}
