@logo-width: 161px;
@logo-height: 110px;

.logo {
  background-repeat: no-repeat;
  background-size: @logo-width @logo-height;
  width: @logo-width;
  height: @logo-height;
  display: inline-block;
}

.big-logo {
  background-repeat: no-repeat;
  background-size: @logo-width*1.8 @logo-height*1.8;
  width: @logo-width*1.8;
  height: @logo-height*1.8;
  display: inline-block;
}

.fcm-logo {
  background-image: url('../../../assets/logos/Cboe_Clear_RGB_Light.svg');
}

.dm-logo {
  background-image: url('../../../assets/logos/Cboe_Clear_RGB_Dark.svg');
}

.centered-content {
  display: flex;
  justify-content: center;
}

#top-nav {
  // margin-top: 4px;
  grid-column: content;
  grid-row: span header;
  width: 100%;
  // height: @top-nav-height;
  display: flex;
  z-index: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-area: top-nav;
  .profile {
    width: @profile-icon;
    height: @profile-icon;
    border-radius: 100%;
    border: 2px solid @green;
    align-self: center;
  }

  .logo-container.hide {
    display: none;
  }

  .logo-container {
    margin-right: auto;

    .logo {
      margin-top: 40px;
    }
  }

  img.user-picture {
    border-radius: 100%;
    width: 45px;
    height: 45px;
  }

  .user-dropdown {
    display: inline-flex;
    align-items: center;
  }

  // don't break button text
  .deposit,
  .withdraw,
  .trade {
    white-space: nowrap;
  }
}

#top-nav:not(.mobile) {
  .button.deposit,
  .button.withdraw,
  .button.trade {
    margin-right: 1em;
  }
}

.logo-container .small-logo {
  background-image: url('../../../assets/logos/ErisX_Secondary_Green_REV_RGB.svg');
  background-repeat: no-repeat;
  background-size: @logo-height / 2 @logo-height / 2;
  width: @logo-height / 2;
  height: @logo-height / 2;
}

.logo-container .onboarding-logo {
  background-image: url('../../../assets/logos/ErisX_Secondary_Green_REV_RGB.svg');
  background-repeat: no-repeat;
  background-size: @logo-height / 2 @logo-height / 2;
  width: @logo-height / 2;
  height: @logo-height / 2;
  margin: 10px auto;
}

#top-nav.mobile {
  height: max-content;
  align-items: center;

  .logo-container .small-logo {
    background-image: url('../../../assets/logos/ErisX_Secondary_Green_REV_RGB.svg');
    background-repeat: no-repeat;
    background-size: @logo-height / 2 @logo-height / 2;
    width: @logo-height / 2;
    height: @logo-height / 2;
    cursor: pointer;
  }

  .logo-container .onboarding-logo {
    background-image: url('../../../assets/logos/ErisX_Secondary_Green_REV_RGB.svg');
    background-repeat: no-repeat;
    background-size: @logo-height / 2 @logo-height / 2;
    width: @logo-height / 2;
    height: @logo-height / 2;
    margin: 10px auto;
  }

  .user-dropdown {
    margin-right: 0.125em;
    .icon.angle {
      display: none;
    }
  }

  // shrink padding somewhat to fit items before hitting "extra-small" size
  .deposit,
  .withdraw,
  .trade {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}

#top-nav.mobile.extra-small {
  margin: 0 0.25em 0em 0.125em;
  display: grid !important;
  grid-template-columns: [left] @logo-height / 2 [middle-left] 1f [middle-right] 1f [right] @logo-height / 2;
  grid-template-rows: [top] @logo-height / 2 [middle] 1f [bottom] 1f;
  grid-template-areas:
    'logo acc-sel total-stat user-dropdown'
    'dw dw if if';
  grid-row-gap: 0.25em;
  justify-content: stretch;
  align-content: stretch;
  margin-bottom: 10px;
  padding-bottom: 10px;
  .small-logo {
    grid-area: logo;
    align-self: center;
    justify-self: left;
  }

  .user-dropdown {
    grid-area: user-dropdown;
    align-self: center;
    justify-self: right;
  }

  .deposit {
    width: 50%;
  }
  .withdraw {
    width: 50%;
  }
  .trade {
    grid-area: trade;
    margin: 0px 7px 0px 3px;
  }
}

#top-nav.medium {
  margin: 0 0.25em 0em 0.125em;
  display: grid;
  grid-template-columns: [left] @logo-height / 2 [middle-left] 1f [middle-right] 1f [right] @logo-height / 2;
  grid-template-rows: [top] @logo-height / 2 [middle] 1f [bottom] 1f;
  grid-template-areas:
    'acc-sel total-stat total-stat user-dropdown'
    'dw dw if .';
  grid-row-gap: 0.25em;
  justify-content: stretch;
  align-content: stretch;
  margin-bottom: 10px;
  padding-bottom: 10px;
  height: max-content;

  .user-dropdown {
    grid-area: user-dropdown;
  }
}

#top-nav.simple {
  background-color: transparent;
}

#top-nav.simple .small-logo {
  cursor: normal;
}

#mfa-button {
  color: white !important;
  border-color: white !important;
  background-color: transparent !important;
  margin-left: 20px;
  margin-bottom: 6px;
  @media (max-width: @largestMobileScreen) {
    margin: 3px 0px 2px 0rem !important;
  }
}

#total-balance-stat {
  grid-area: total-stat;
  padding-left: 5px;
  flex-shrink: 0;
  .label {
    text-align: left;
  }

  .value {
    .icon {
      margin-left: 4px;
    }
  }
}

img.user-picture-mobile {
  border-radius: 100%;
  width: 45px;
  height: 45px;
}

.logo-container {
  .mobile {
    align-self: center;
  }
}
