/*******************************
         Theme Overrides
*******************************/

.ui.checkbox input ~ label:before,
.ui.checkbox input:checked ~ label:before, {
  border: @checkboxBorder !important;
  background-color: @checkboxBackground !important;
}

.ui.checkbox input ~ label:after {
  content: '';
  width: 9px;
  height: 9px;
  top: 4px;
  left: 4px;
  transform: scale(0);
  opacity: 0;
  transition: all @defaultDuration @defaultEasing;
}

/* Checked */
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  content: '';
  width: 9px;
  height: 9px;
  top: 4px;
  left: 4px;
  transform: scale(1);
  opacity: 1;
}

/* Indeterminate */
.ui.checkbox input:indeterminate ~ .box:after,
.ui.checkbox input:indeterminate ~ label:after {
  font-size: 9px;
  content: '\e801';
  width: 9px;
  height: 9px;
  top: 4px;
  left: 4px;
}
