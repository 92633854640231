.order-entry-header {
  color: @green !important;
  display: flex;
  justify-content: center;
  .ui.header {
    padding-top: 0px;
    color: @green !important;
    margin-bottom: 5px;
    padding-right: 10px;
  }
}
